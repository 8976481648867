{
  "name": "sbx-community-admin-template",
  "version": "1.0.22",
  "license": "MIT",
  "config": {
    "coreui_library_short_version": "4.7",
    "coreui_library_docs_url": "https://coreui.io/angular/docs/"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build-uat": "ng build --configuration uat",
    "build-sec": "ng build --configuration sec",
    "build-preprod": "ng build --configuration preprod",
    "build-prod": "ng build --configuration production",
    "watch": "ng build --watch --configuration development",
    "package": "ng build --configuration production",
    "package-serve": "ng serve --configuration production",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.2.1",
    "@angular/cdk": "^17.2.0",
    "@angular/common": "^17.2.1",
    "@angular/compiler": "^17.2.1",
    "@angular/core": "^17.2.1",
    "@angular/forms": "^17.2.1",
    "@angular/language-service": "^17.2.1",
    "@angular/platform-browser": "^17.2.1",
    "@angular/platform-browser-dynamic": "^17.2.1",
    "@angular/router": "^17.2.1",
    "@coreui/angular": "~4.7.15",
    "@coreui/angular-chartjs": "~4.7.14",
    "@coreui/chartjs": "^3.1.2",
    "@coreui/coreui": "~4.2.6",
    "@coreui/icons-angular": "~4.7.14",
    "@coreui/icons-pro": "^3.0.0",
    "@coreui/utils": "^2.0.2",
    "@danielmoncada/angular-datetime-picker": "^17.0.0",
    "@danielmoncada/angular-datetime-picker-moment-adapter": "^4.0.0",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@popperjs/core": "^2.11.8",
    "bootstrap": "^5.3.2",
    "chart.js": "^3.9.1",
    "dompurify": "^3.1.6",
    "franc": "^6.2.0",
    "jquery": "^3.7.1",
    "lodash-es": "^4.17.21",
    "moment": "^2.30.1",
    "ngx-scrollbar": "^13.0.3",
    "ngx-select-dropdown": "^3.3.2",
    "ngx-summernote": "^1.0.0",
    "ngx-toastr": "^18.0.0",
    "reading-time": "^1.5.0",
    "rxjs": "~7.8.1",
    "summernote": "^0.8.20",
    "toastr": "^2.1.4",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.0",
    "@angular/cli": "^17.2.0",
    "@angular/compiler-cli": "^17.2.1",
    "@angular/localize": "^17.2.1",
    "@types/dompurify": "^3.0.5",
    "@types/jasmine": "^5.1.4",
    "@types/jquery": "^3.5.30",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^20.11.18",
    "jasmine-core": "^5.1.2",
    "karma": "^6.4.2",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "typescript": "~5.3.3"
  },
  "engines": {
    "node": ">=18.13 <21",
    "npm": ">= 9"
  },
  "overrides": {
    "ngx-summernote": {
      "@angular/common": "14.x - 17.x",
      "@angular/core": "14.x - 17.x",
      "@angular/forms": "14.x - 17.x"
    }
  }
}