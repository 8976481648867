<div class="content-side-panel">
  <div class="card bg-gray-light border-light">
    <div class="card-body p-4">
      <c-row>
        <c-col md="6" xl="12">
          <div class="mb-4">
            <div class="data-label">Content type</div>
            <div class="data">Learning Topic</div>
          </div>
        </c-col>

        <c-col md="6" xl="12">
          <div class="mb-4">
            <div class="data-label">Content ID</div>
            <div class="data">XXXXXXXX-XXXXX-XXXXX-XX-XXXXX</div>
          </div>
        </c-col>

        <c-col md="6" xl="12">
          <div class="mb-4">
            <div class="data-label">Publish period</div>
            <div class="data">1 May 2024 - 1 Dec 2024</div>
          </div>
        </c-col>

        <c-col md="6" xl="12">
          <div class="mb-4">
            <div class="data-label">Black Apron exclusive</div>
            <div class="data">No</div>
          </div>
        </c-col>

        <c-col md="6" xl="12">
          <div class="mb-2">
            <div class="data-label">Language</div>
            <div class="data">English</div>
          </div>
        </c-col>
      </c-row>
    </div>
    <div class="card-footer">
      <ul class="side-panel-action-list list-unstyled">
        <li *ngIf="config.content_status === 'PENDING_APPROVAL' || config.content_status === 'REJECTED'"
          class="action-item primary">
          <button cButton class="btn-inverted-primary w-100"
            (click)="openApproveContentConfirmationModal('APPROVE_CONTENT')">
            <span class="icon-img me-2">
              <svg cIcon name="cilCheckDoubleAlt"></svg>
            </span> Approve content
          </button>
        </li>

        <li *ngIf="config.content_status === 'UPDATES_PENDING_APPROVAL' || config.content_status === 'UPDATES_REJECTED'"
          class="action-item primary">
          <button cButton class="btn-inverted-primary w-100"
            (click)="openApproveContentConfirmationModal('APPROVE_UPDATES')">
            <span class="icon-img me-2">
              <svg cIcon name="cilCheckDoubleAlt"></svg>
            </span> Approve content
          </button>
        </li>

        <li
          *ngIf="config.content_status === 'APPROVED' || config.content_status === 'UPDATES_PENDING_APPROVAL' || config.content_status === 'UPDATES_REJECTED' || config.content_status === 'UPDATES_APPROVED' && config.content_direct_link"
          class="action-item">
          <div class="d-flex flex-wrap connected-btn">
            <a [href]="config.content_direct_link" cButton class="btn-inverted-outline flex-1" target="_blank">
              <span class="icon-img me-2">
                <svg cIcon name="cilPageview"></svg>
              </span>
              View published page
            </a>
            <button cButton class="btn-inverted-outline p-0 min-width-0" title="Copy published page link">
              <span class="icon-img">
                <svg cIcon name="cilLink"></svg>
              </span>
            </button>
          </div>
        </li>

        <li *ngIf="config.content_preview_link" class="action-item">
          <div class="d-flex flex-wrap connected-btn">
            <a [href]="config.content_preview_link" cButton class="btn-inverted-outline flex-1" target="_blank">
              <span class="icon-img me-2">
                <svg cIcon name="cilPageview"></svg>
              </span>
              Preview
            </a>
            <button cButton class="btn-inverted-outline p-0 min-width-0" title="Copy preview link">
              <span class="icon-img">
                <svg cIcon name="cilLink"></svg>
              </span>
            </button>
          </div>
        </li>

        <!-- If approved or approved with changes -->
        <li *ngIf="config.content_status === 'PENDING_APPROVAL'" class="action-item">
          <button cButton color="danger" class="w-100" (click)="openRejectContentConfirmationModal('REJECT_CONTENT')">
            <span class="icon-img me-2">
              <svg cIcon name="cilMinusCircle"></svg>
            </span>
            Reject content
          </button>
        </li>
        <!-- /If submitted for approval -->

        <!-- If approved or approved with changes -->
        <li *ngIf="config.content_status === 'UPDATES_PENDING_APPROVAL'" class="action-item">
          <button cButton color="danger" class="w-100" (click)="openRejectContentConfirmationModal('REJECT_UPDATES')">
            <span class="icon-img me-2">
              <svg cIcon name="cilMinusCircle"></svg>
            </span>
            Reject content
          </button>
        </li>
        <!-- /If submitted for approval -->
      </ul>
    </div>
  </div>
</div>