import { Component } from '@angular/core';
import { FooterComponent } from '@coreui/angular';
import { version } from '../../../../../package.json';

@Component({
  selector: 'app-default-footer',
  standalone: true,
  templateUrl: './default-footer.component.html',
  styleUrls: ['./default-footer.component.scss'],
  imports: [FooterComponent]
})
export class DefaultFooterComponent extends FooterComponent {
  currentYear!: number;
  currentVersion: string = version;

  constructor() {
    super();
  }

  ngOnInit(): void {
    // Initialize the current year when the component initializes
    this.updateYear();
  }

  updateYear(): void {
    this.currentYear = new Date().getFullYear();
  }
}
