import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { GridModule, FormModule, CardModule, ButtonModule } from '@coreui/angular';

@Component({
  selector: 'app-page404',
  standalone: true,
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss'],
  imports: [CommonModule, GridModule, FormModule, CardModule, ButtonModule]
})
export class Page404Component {

  constructor(private router: Router) { }

  goToHomepage() {
    this.router.navigateByUrl('/home');
  }

}
