<div class="status-summary"
  [class.approved]="config.content_status === 'APPROVED' || config.content_status === 'UPDATES_APPROVED'"
  [class.pending-approval]="config.content_status === 'PENDING_APPROVAL'"
  [class.updates-pending-approval]="config.content_status === 'UPDATES_PENDING_APPROVAL'"
  [class.rejected]="config.content_status === 'REJECTED' || config.content_status === 'UPDATES_REJECTED'"
  [class.deleted]="config.content_status === 'DELETED'">
  <div class="card">
    <div class="card-body">
      <div class="top d-flex flex-wrap">
        <figure class="status-icon">
          <ng-container *ngIf="config.content_status === 'APPROVED' || config.content_status === 'UPDATES_APPROVED'">
            <svg cIcon name="cilCheckCircle"></svg>
          </ng-container>

          <ng-container
            *ngIf="config.content_status === 'PENDING_APPROVAL' || config.content_status === 'UPDATES_PENDING_APPROVAL'">
            <svg cIcon name="cilAvTimer"></svg>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'REJECTED'">
            <svg cIcon name="cilExclamationCircle"></svg>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'UPDATES_REJECTED'">
            <svg cIcon name="cilExclamationCircle"></svg>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'DELETED'">
            <svg cIcon name="cilExclamationCircle"></svg>
          </ng-container>

        </figure>
        <div class="status flex-1">
          <ng-container *ngIf="config.content_status === 'APPROVED'">
            <h3>This content is approved</h3>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'UPDATES_APPROVED'">
            <h3>Updates approved</h3>
            <div class="mt-1 gray-700">Content changes submitted on <strong>8:45 PM, 26 Feb 2024</strong> is approved
            </div>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'PENDING_APPROVAL'">
            <h3>Pending approval</h3>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'UPDATES_PENDING_APPROVAL'">
            <h3>Updates pending approval</h3>
            <div class="mt-1 gray-700">Content changes submitted on <strong>8:45 PM, 26 Feb 2024</strong> is pending
              approval</div>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'REJECTED'">
            <h3>This content was rejected</h3>
            <div class="mt-2 gray-700 text-danger ">
              <div class="fs-smaller gray-600">Reason</div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
              dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor
            </div>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'UPDATES_REJECTED'">
            <h3>Updates was rejected</h3>
            <div class="mt-2 gray-700">Content changes submitted on <strong>8:45 PM, 26 Feb 2024</strong> is rejected
            </div>
            <div class="mt-3 text-danger">
              <div class="fs-smaller gray-600">Reason</div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida
              dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor
            </div>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'DELETED'">
            <h3>This content is deleted</h3>
          </ng-container>

        </div>
        <div class="action">
          <button cButton color="link" size="sm" (click)="openVersionHistoryModal()">Version history <span
              class="icon-img ms-1">
              <svg cIcon name="cilChevronRightAlt" size="sm"></svg>
            </span></button>
        </div>
      </div>
      <div class="middle">
        <c-row>
          <ng-container
            *ngIf="config.content_status === 'UPDATES_PENDING_APPROVAL' || config.content_status === 'UPDATES_REJECTED' || config.content_status === 'UPDATES_APPROVED'">
            <c-col sm="6" xxl="4" class="mb-3">
              <div class="data-label">
                Lasted edited on
              </div>
              <div class="data">
                12:02 PM, 26 Feb 2024 (GMT+8)
                <div class="gray-600 fs-smaller fw-normal"><i>John doe</i></div>
              </div>
            </c-col>

            <c-col sm="6" xxl="4" class="mb-3">
              <div class="data-label">
                Lasted approved on
              </div>
              <div class="data">
                12:02 PM, 26 Feb 2024 (GMT+8)
                <div class="gray-600 fs-smaller fw-normal"><i>Selina Teoh</i></div>
              </div>
            </c-col>
          </ng-container>

          <ng-container *ngIf="config.content_status !== 'DELETED'">
            <c-col sm="6" xxl="4" class="mb-3">
              <div class="data-label">
                Maker
              </div>
              <div class="data">
                John Doe
              </div>
            </c-col>

            <c-col sm="6" xxl="4" class="mb-3">
              <div class="data-label">
                Created on
              </div>
              <div class="data">
                12:02 PM, 26 Feb 2024 (GMT+8)
              </div>
            </c-col>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'APPROVED'">
            <c-col sm="6" xxl="4" class="mb-3">
              <div class="data-label">
                Approved on
              </div>
              <div class="data">
                12:02 PM, 26 Feb 2024 (GMT+8)
                <div class="gray-600 fs-smaller fw-normal"><i>Jon Appleseed</i></div>
              </div>
            </c-col>
          </ng-container>



          <ng-container *ngIf="config.content_status === 'REJECTED' || config.content_status === 'UPDATES_REJECTED'">
            <c-col sm="6" xxl="4" class="mb-3">
              <div class="data-label">
                Rejected on
              </div>
              <div class="data">
                12:02 PM, 26 Feb 2024 (GMT+8)
                <div class="gray-600 fs-smaller fw-normal"><i>Jon Appleseed</i></div>
              </div>
            </c-col>
          </ng-container>

          <ng-container *ngIf="config.content_status === 'DELETED'">
            <c-col sm="6" xxl="4" class="mb-3">
              <div class="data-label">
                Deleted by
              </div>
              <div class="data">
                Kenji Yokohama
              </div>
            </c-col>

            <c-col sm="6" xxl="4" class="mb-3">
              <div class="data-label">
                Deleted on
              </div>
              <div class="data">
                12:02 PM, 26 Feb 2024 (GMT+8)
              </div>
            </c-col>
          </ng-container>

          <ng-container
            *ngIf="config.content_status === 'PENDING_APPROVAL' || config.content_status === 'UPDATES_PENDING_APPROVAL' || config.content_status === 'UPDATES_REJECTED'">
            <c-col sm="6" xxl="4" class="mb-3">
              <div class="data-label">
                Change logs
              </div>
              <div class="data">
                Updated banner image and cover image
              </div>
            </c-col>
          </ng-container>
        </c-row>
      </div>

      <div *ngIf="config.content_status === 'REJECTED' || config.content_status === 'UPDATES_REJECTED'" class="bottom">
        <div class="d-flex flex-wrap justify-content-end">
          <button cButton color="primary" size="sm" class="m-1" (click)="notifyCreator()">Notify creator</button>
        </div>
      </div>
    </div>
  </div>
</div>