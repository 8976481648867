import { Component } from '@angular/core';
import { GridModule, ButtonModule, CardComponent } from '@coreui/angular';

@Component({
  selector: 'app-email-templates',
  standalone: true,
  imports: [GridModule, ButtonModule, CardComponent],
  templateUrl: './email-templates.component.html',
  styleUrl: './email-templates.component.scss'
})
export class EmailTemplatesComponent {

}
