import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule, CardModule, ButtonModule } from '@coreui/angular';

@Component({
  selector: 'app-account-activated',
  standalone: true,
  templateUrl: './account-activated.component.html',
  styleUrl: './account-activated.component.scss',
  imports: [CommonModule, GridModule, CardModule, ButtonModule]
})
export class AccountActivatedComponent implements OnInit {
  email: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.email = atob(params['e'] ?? '');
    })
  }

  goToLogin() {
    this.router.navigate(['/login'], { queryParams: { e: btoa(this.email) } });
  }
}
