<div class="manage-content">
  <section class="page-header">
    <c-container>
      <div class="page-heading page-heading-with-nav d-flex flex-wrap pb-lg-4">
        <div class="pe-3 border-end">
          <button cButton variant="ghost" color="primary" class="btn-square btn-cancel" (click)="goBack()"><svg cIcon
              name="cilChevronLeftAlt" size="xxl"></svg></button>
        </div>
        <h1 class="mb-0 ps-3 flex-1">Check [Content's name] Order Settings</h1>
      </div>
    </c-container>
  </section>

  <section class="page-contents">
    <c-container>
      <c-row>
        <c-col xl="8" class="left-panel-col">
          <div class="left-panel">
            <div class="mb-4">
              <app-checker-status-summary [config]="statusSummaryConfig"></app-checker-status-summary>
            </div>

            <div *ngIf="selectedContent" class="content-list mt-3">
              <ul class="list-unstyled">
                <li class="content-list-item mw-100" *ngFor="let item of selectedContent; let i = index">
                  <div class="card bg-gray-light">
                    <div class="card-body d-flex flex-wrap position-relative">

                      <div class="content-wrapper">
                        <div class="mb-3 mb-md-0 pe-3">#{{ i + 1 }}</div>
                        <figure *ngIf="item.img_url" class="image-preview">
                          <img [src]="item.img_url">
                        </figure>
                        <div class="info" [class.ps-0]="!item.img_url">
                          <div *ngIf="item.name" class="name"><strong>{{ item.name }}</strong></div>
                          <div *ngIf="item.id" class="id fs-smaller gray-500">{{ item.id }}</div>
                          <div *ngIf="item.active_period" class="active-period fs-small gray-800 mt-2">
                            {{item.active_period}}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </c-col>
        <c-col xl="4" class="right-panel-col">
          <div class="right-panel">
            <app-checker-content-side-panel [config]="sidePanelConfig"></app-checker-content-side-panel>
          </div>

        </c-col>
      </c-row>
    </c-container>
  </section>
</div>