import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { checkerNavItems, makerNavItems, navItems, securityNavItems, superNavItems } from './_nav';
import { DefaultHeaderComponent } from './default-header/default-header.component';
import { DefaultFooterComponent } from './default-footer/default-footer.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';

import {
  GridModule,
  SidebarModule
} from '@coreui/angular';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-default-layout',
  standalone: true,
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  imports: [SidebarModule, GridModule, DefaultHeaderComponent, RouterOutlet, DefaultFooterComponent, NgScrollbarModule, CommonModule]
})
export class DefaultLayoutComponent implements OnInit {
  public navItems!: any;
  adminRole!: string;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.adminRole = this.authService.getUserRole();

    switch (this.adminRole) {
      case 'ROLE_SUPER_ADMIN':
        this.navItems = superNavItems;
        break;
      case 'ROLE_SECURITY_ADMIN':
        this.navItems = securityNavItems;
        break;
      case 'ROLE_MAKER_ADMIN':
        this.navItems = makerNavItems;
        break;
      case 'ROLE_CHECKER_ADMIN':
        this.navItems = checkerNavItems;
        break;
      default:
        this.navItems = navItems;
        break;
    }
  }
}
