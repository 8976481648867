<!--sidebar-->
<c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible>
  <c-sidebar-brand [brandFull]="{
      src: 'assets/images/brand/img-logo-sbx-white.svg',
      width: 150,
      height: 42,
      alt: 'Starbucks Coffee Community Admin Portal'
    }" [brandNarrow]="{
      src: 'assets/images/brand/img-logo-sbx-white.svg',
      width: 46,
      height: 46,
      alt: 'Starbucks Coffee Community Admin Portal'
    }" routerLink="./" />

  <ng-scrollbar pointerEventsMethod="scrollbar">
    <c-sidebar-nav [navItems]="navItems" dropdownMode="none" />
  </ng-scrollbar>
  <c-sidebar-toggler *ngIf="!sidebar.narrow" toggle="visible" cSidebarToggle="sidebar" class="custom" />
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-body dark:bg-transparent">
  <!--app-header-->
  <app-default-header class="d-print-none header header-sticky" position="sticky" sidebarId="sidebar" />
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer class="bg-body-dark" />
</div>