import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GridModule } from '@coreui/angular';
import { version } from '../../../../package.json';

@Component({
  selector: 'app-public-layout',
  standalone: true,
  imports: [RouterOutlet, GridModule],
  templateUrl: './public-layout.component.html',
  styleUrl: './public-layout.component.scss'
})
export class PublicLayoutComponent implements OnInit {
  currentYear!: number;
  currentVersion: string = version;

  constructor() { }

  ngOnInit(): void {
    // Initialize the current year when the component initializes
    this.updateYear();
  }

  updateYear(): void {
    this.currentYear = new Date().getFullYear();
  }
}
