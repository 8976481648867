<div class="bg-body flex-1 d-flex flex-row align-items-center py-5">
  <c-container class="mw-lg">
    <div class="d-flex justify-content-center">
      <c-card class="p-4">
        <c-card-body>
          <form cForm [formGroup]="forgetPasswordForm">
            <div class="mb-4">
              <h1>Forgot Password</h1>
            </div>

            <div class="mb-4">
              <app-text-input [config]="inputEmailConfig" (onChange)="setTextInputValue($event)"
                (keyup.enter)="forgetPasswordForm.controls['email-input'].value === '' ? null : submitForgotPassword()"
                autoComplete="username"></app-text-input>
              <div class="invalid-message py-1" *ngIf="error">{{errorMessage}}</div>
            </div>

            <c-row>
              <c-col xs="6" class="mb-2">
                <button cButton class="px-4" color="primary" (click)="submitForgotPassword()"
                  [disabled]="isSubmitting || forgetPasswordForm.controls['email-input'].value === ''">
                  <span *ngIf="!isSubmitting">Continue</span>
                  <span *ngIf="isSubmitting" class="icon-img">
                    <icon-spinner></icon-spinner>
                  </span>
                </button>
              </c-col>
            </c-row>
          </form>
        </c-card-body>
      </c-card>
    </div>
  </c-container>
</div>