import { Component } from '@angular/core';
import { ButtonModule } from '@coreui/angular';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-create-content-successful',
  standalone: true,
  imports: [ButtonModule, RouterModule],
  templateUrl: './create-content-successful.component.html',
  styleUrl: './create-content-successful.component.scss'
})
export class CreateContentSuccessfulComponent {

}
