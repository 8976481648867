import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule, FormModule, CardModule, ButtonModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { ValidationService } from 'src/app/_services/validation.service';
import { ErrorService } from 'src/app/_services/error.service';
import { AuthService } from 'src/app/_services/auth.service';
import { InputTextModel } from 'src/app/_models/form-field.model';
import { InputType } from 'src/app/enum';
import { TextInputComponent } from 'src/app/components/forms';
import { SpinnerIcon } from 'src/app/icons/icon-spinner';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [CommonModule, GridModule, FormModule, CardModule, ButtonModule, IconModule, ReactiveFormsModule, TextInputComponent, SpinnerIcon],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent implements OnInit {
  forgetPasswordForm!: FormGroup;
  isSubmitting: boolean = false;

  // Error
  error: boolean = false;
  errorMessage!: string;

  // Form input - Email
  inputEmailConfig: InputTextModel = {
    id: 'email-input',
    name: 'email-input',
    label: 'Email address',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    type: InputType.Email,
    required: true
  };

  constructor(
    private router: Router,
    private validationService: ValidationService,
    private errorService: ErrorService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.forgetPasswordForm = new FormGroup({
      'email-input': new FormControl('', [Validators.required])
    });

    this.forgetPasswordForm.controls['email-input'].valueChanges
      .pipe(
        debounceTime(500),
        // take(1)
      )
      .subscribe(
        (val: string) => {
          const sanitizedVal = val.replace(/\s/g, "").toLowerCase();
          this.forgetPasswordForm.controls['email-input'].patchValue(sanitizedVal, { emitEvent: false });
          this.inputEmailConfig.value = sanitizedVal;
        }
      )
  }

  submitForgotPassword() {
    this.isSubmitting = true;
    if (this.validationService.validateEmail(this.forgetPasswordForm.controls['email-input'].value)) { // Frontend email validation
      // POST: Request Reset Password
      this.authService.requestResetPassword(this.forgetPasswordForm.controls['email-input'].value).subscribe({
        next: res => {
          this.isSubmitting = false;
          if (!res.data) {
            this.errorMessage = res.error;
            this.error = true;
          } else {
            this.router.navigate(['/forgot-password-notice'], { queryParams: { e: btoa(this.forgetPasswordForm.controls['email-input'].value) ?? '' } });
          }
        }
      })
    } else {
      this.errorMessage = this.errorService.getFrontendErrorMapping('5002');
      this.error = true;
      this.isSubmitting = false;
    }
  }

  setTextInputValue(newValue: { formControlName: string, value: string }) {
    this.forgetPasswordForm.controls[newValue.formControlName].setValue(newValue.value);
    this.error = false;
  }
}
