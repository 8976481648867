import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule, FormModule, CardModule, ButtonModule, TooltipDirective } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { TextInputComponent } from "../../components/forms/";
import { InputType } from 'src/app/enum';
import { AbstractControl, FormControl, FormGroup, FormsModule, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { SpinnerIcon } from 'src/app/icons/icon-spinner';
import { ValidationService } from 'src/app/_services/validation.service';
import { ErrorService } from 'src/app/_services/error.service';
import { environment } from 'src/environment/environment';

@Component({
  selector: 'app-first-time-login',
  standalone: true,
  templateUrl: './first-time-login.component.html',
  styleUrl: './first-time-login.component.scss',
  imports: [CommonModule, GridModule, FormModule, CardModule, ButtonModule, TooltipDirective, IconModule, TextInputComponent, FormsModule, SpinnerIcon]
})
export class FirstTimeLoginComponent implements OnInit, OnDestroy {
  requestOTPCountdown: number = 60;
  requestOTPCountdownStart: boolean = false;
  requestOTPButtonText: string = 'Request OTP';
  requestOTPInterval!: any;
  isRequestingOTP: boolean = false;

  activationToken!: string;
  otpValue: string = '';

  // Form group
  firstTimeLoginForm !: FormGroup;
  isSubmitting: boolean = false;

  errorList!: string[];
  error: boolean = false;
  errorMessage!: string;
  landingError: boolean = false;
  landingErrorCode!: string;
  landingErrorMessage!: string;


  // Form input - Email
  inputEmailConfig = {
    id: 'email-input',
    name: 'email-input',
    label: 'Email',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: true,
    type: InputType.Email,
  };

  // Form input - Password
  inputPasswordConfig = {
    id: 'password-input',
    name: 'password-input',
    label: 'New password',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    type: InputType.Password,
    required: true,
    show_password_tooltips: true,
    password_tooltips_error_list: []
  };

  // Form input - Confirm Password
  inputConfirmPasswordConfig = {
    id: 'confirm-password-input',
    name: 'confirm-password-input',
    label: 'Confirm password',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    type: InputType.Password,
    required: true
  };


  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private validationService: ValidationService,
    private errorService: ErrorService
  ) { }

  ngOnDestroy(): void {
    clearInterval(this.requestOTPInterval);
  }

  ngOnInit(): void {
    this.firstTimeLoginForm = new FormGroup({
      'email-input': new FormControl(this.inputEmailConfig.value, [Validators.required]),
      'password-input': new FormControl('', [Validators.required, this.passwordValidator()]),
      'confirm-password-input': new FormControl('', [Validators.required])
    })

    this.route.queryParams.subscribe(params => {
      if (!params['t']) {
        this.landingErrorMessage = "Oops! Looks like that activation link is invalid. Please request a new one.";
        this.landingError = true;
        return;
      }
      this.activationToken = params['t'];

      this.authService.verifyActivation(this.activationToken).subscribe({
        next: res => {

          if (res.data) {
            if (this.authService.getUserAccessToken()) {
              localStorage.removeItem('auth'); // Manually logout
              if (environment.clientAuthEnabled) {
                this.authService.clientAuth(this.authService.getClientAccessToken());
              }
            }

            this.inputEmailConfig.value = res.data; // request return email
            this.firstTimeLoginForm.controls['email-input'].setValue(res.data);
            return;
          }

          this.landingErrorMessage = res.error;
          this.landingErrorCode = res.error_code;
          this.landingError = true;
        },
        error: err => {
          this.landingErrorMessage = err.error_description;
          this.landingError = true;
        }
      })
    })
  }

  goToLogin() {
    this.router.navigateByUrl('/login');
  }

  onRequestOTP(): void {
    this.isRequestingOTP = true;

    // Password criteria error
    if (this.firstTimeLoginForm.controls['password-input'].errors) {
      window.postMessage('invalidPassword');
      this.errorMessage = this.errorService.getFrontendErrorMapping('5000');
      this.error = true;
      this.isRequestingOTP = false;
      return;
    }

    // Mismatch password
    if (this.firstTimeLoginForm.controls['password-input'].value !== this.firstTimeLoginForm.controls['confirm-password-input'].value) {
      this.errorMessage = this.errorService.getFrontendErrorMapping('5001');
      this.error = true;
      this.isRequestingOTP = false;
      return;
    }

    if (this.validationService.validateEmail(this.firstTimeLoginForm.controls['email-input'].value)) {
      this.authService.requestOtpForActivation(this.activationToken, this.firstTimeLoginForm.controls['password-input'].value).subscribe({
        next: res => {
          if (res.data) {
            this.requestOTPCountdown = 60;
            this.requestOTPCountdownStart = true;
            this.requestOTPInterval = setInterval(() => {
              this.requestOTPCountdown--;
              if (this.requestOTPCountdown === 0) {
                clearInterval(this.requestOTPInterval);
                this.requestOTPCountdownStart = false;
                this.requestOTPButtonText = 'Request OTP';
              } else {
                this.requestOTPButtonText = `Request OTP (${this.requestOTPCountdown})`;
              }
            }, 1000);
            this.isRequestingOTP = false;
            return;
          }
          clearInterval(this.requestOTPInterval);
          this.requestOTPButtonText = 'Request OTP';
          this.requestOTPCountdownStart = false;
          this.errorMessage = res.error;
          this.error = true;
        }
      })
    } else {
      this.errorMessage = this.errorService.getFrontendErrorMapping('5002');
      this.error = true;
    }
    this.isRequestingOTP = false;
  }

  onActivateAccount() {
    this.isSubmitting = true;

    // Password criteria error
    if (this.firstTimeLoginForm.controls['password-input'].errors) {
      window.postMessage('invalidPassword');
      this.errorMessage = this.errorService.getFrontendErrorMapping('5000');
      this.error = true;
      this.isSubmitting = false;
      return;
    }

    // Mismatch password
    if (this.inputPasswordConfig.value !== this.inputConfirmPasswordConfig.value) {
      this.isSubmitting = false;
      this.errorMessage = this.errorService.getFrontendErrorMapping('5001');
      this.error = true;
      return;
    }

    if (this.validationService.validateEmail(this.firstTimeLoginForm.controls['email-input'].value)) {
      this.authService.submitActivation(this.activationToken, this.firstTimeLoginForm.controls['password-input'].value, this.otpValue).subscribe({
        next: res => {
          this.isSubmitting = false;
          if (res.data) {
            this.router.navigate(['/account-activated'], { queryParams: { e: btoa(this.inputEmailConfig.value) } });
            return;
          }

          this.errorMessage = res.error;
          this.error = true;
        }
      })
    } else {
      this.errorMessage = this.errorService.getFrontendErrorMapping('5002');
      this.error = true;
    }
    this.isSubmitting = false;
  }

  setFormValue(data: { formControlName: string, value: string }) {
    this.error = false;
    this.errorMessage = '';
    this.firstTimeLoginForm.controls[data.formControlName].setValue(data.value);
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password: string = control.value;

      // Password validator criteria
      this.inputPasswordConfig.password_tooltips_error_list = this.validationService.validatePassword(password);
      this.errorList = this.validationService.validatePassword(password);

      if (this.errorList.length >= 1) {
        const firstError = this.errorList[0];
        return {
          [firstError]: true
        }
      }
      return null;
    }
  }
}
