<div class="bg-body flex-1 d-flex flex-row align-items-center py-5">
  <c-container class="mw-lg">
    <c-row class="justify-content-center text-center text-md-start">
      <c-col md="3" lg="3">
        <h1 class="text-md-end">404</h1>
      </c-col>
      <c-col md="5">
        <h2>Oops! You're Lost.</h2>
        <p>
          The page you are looking for was not found.
        </p>
        <button cButton color="primary" (click)="goToHomepage()">Go to Homepage</button>
      </c-col>
    </c-row>
  </c-container>
</div>