<div class="bg-body flex-1 d-flex flex-row align-items-center py-5">
  <c-container class="mw-lg">
    <div class="d-flex justify-content-center">
      <c-card class="p-4">
        <c-card-body>
          <form cForm>
            <div class="mb-5">
              <h6 class="text-dark">Starbucks Coffee Community Admin Portal</h6>
              <h1>Log In To Continue</h1>
            </div>

            <div class="mb-4" [formGroup]="loginForm">
              <div class="form-group mb-3">
                <app-text-input [config]="inputEmailConfig" (onChange)="setTextInputValue($event)"
                  autoComplete="username"></app-text-input>
              </div>

              <div class="form-group mb-3">
                <app-text-input [config]="inputPasswordConfig" (onChange)="setTextInputValue($event)"
                  autoComplete="current-password"></app-text-input>
              </div>

              <div class=" form-group mb-3">
                <label cLabel for="pin-input">
                  OTP
                </label>
                <c-input-group class="mb-2">
                  <input id="pin-input" autocomplete="one-time-code" cFormControl placeholder="Enter here" type="text"
                    formControlName="pin" name="pin-input" />
                  <button cButton color="primary"
                    [disabled]="isRequestingOTP || requestOTPCountdownStart || loginForm.controls['email-input'].value === '' || loginForm.controls['password-input'].value === ''"
                    (click)="error = false; errorMessage = ''; onRequestOTP()">
                    <span *ngIf="!isRequestingOTP">{{ requestOTPButtonText }}</span>
                    <span *ngIf="isRequestingOTP" class="icon-img">
                      <icon-spinner></icon-spinner>
                    </span>
                  </button>
                </c-input-group>
                <div *ngIf="requestOTPCountdownStart">
                  <small><i>A one-time pin has been sent to your email</i></small>
                </div>
              </div>
              <div class="invalid-message py-1" *ngIf="error">{{errorMessage}}</div>
            </div>

            <c-row>
              <c-col sm="6" class="mb-2">
                <button cButton class="px-4" color="primary" [disabled]="!loginForm.valid" (click)="onSubmitLogin()">
                  <span *ngIf="!isSubmitting">Login</span>
                  <span *ngIf="isSubmitting" class="icon-img">
                    <icon-spinner></icon-spinner>
                  </span>
                </button>
              </c-col>
              <c-col class="text-sm-end" sm="6">
                <button cButton class="px-0" color="link" (click)="goToForgotPassword()">
                  Forgot password?
                </button>
              </c-col>
            </c-row>
          </form>
        </c-card-body>
      </c-card>
    </div>
  </c-container>
</div>