<div class="bg-body flex-1 d-flex flex-row align-items-center py-5">
  <c-container class="mw-lg">
    <div class="d-flex justify-content-center">
      <c-card class="p-4">
        <c-card-body>
          <form cForm>
            <div class="mb-4">
              <h1>Reset Password Successful</h1>
            </div>

            <div class="mb-4">
              Your password has been successfully reset. Please log in using your new password.
            </div>

            <c-row>
              <c-col xs="6" class="mb-2">
                <button cButton class="px-4" color="primary" (click)="goToLogin()">
                  Back to Log In
                </button>
              </c-col>
            </c-row>
          </form>
        </c-card-body>
      </c-card>
    </div>
  </c-container>
</div>