import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, GridModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApproveContentConfirmationModalComponent, RejectContentConfirmationModalComponent, VersionHistoryModalComponent } from '../modal';

@Component({
  selector: 'app-checker-content-side-panel',
  standalone: true,
  imports: [ButtonModule, GridModule, IconModule, CommonModule],
  templateUrl: './checker-content-side-panel.component.html',
  styleUrl: './checker-content-side-panel.component.scss'
})
export class CheckerContentSidePanelComponent implements OnInit {
  @Input()
  config!: {
    content_status: string;
    content_id: {
      value?: string;
      disabled?: boolean;
      error_message?: string;
    },
    content_direct_link?: string;
    content_preview_link?: string;
    publish_period: {
      start_date?: string;
      start_date_disabled?: boolean;
      end_date?: string;
      end_date_disabled?: boolean;
      error_message?: string;
    },
    bap_level: {
      disabled?: boolean;
    },
    language?: {
      current_language: string;
    }
  };

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void { }

  openApproveContentConfirmationModal(event_session: string) {
    const modalRef = this.modalService.open(ApproveContentConfirmationModalComponent, {
      centered: true,
      windowClass: 'approve-content-confirmation-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.event_session = event_session;
  }

  openRejectContentConfirmationModal(event_session: string) {
    const modalRef = this.modalService.open(RejectContentConfirmationModalComponent, {
      centered: true,
      windowClass: 'reject-content-confirmation-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.event_session = event_session;
  }

  openVersionHistoryModal() {
    const modalRef = this.modalService.open(VersionHistoryModalComponent, {
      centered: true,
      windowClass: 'version-history-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
  }
}
