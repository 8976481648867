import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { IconModule } from '@coreui/icons-angular';
import { GridModule, ButtonModule, FormModule } from '@coreui/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VersionHistoryModalComponent } from '../modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-checker-status-summary',
  standalone: true,
  imports: [GridModule, ButtonModule, IconModule, CommonModule, FormModule],
  templateUrl: './checker-status-summary.component.html',
  styleUrl: './checker-status-summary.component.scss'
})
export class CheckerStatusSummaryComponent {
  @Input()
  config!: {
    content_status: string;
  };

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService
  ) { }

  openVersionHistoryModal() {
    const modalRef = this.modalService.open(VersionHistoryModalComponent, {
      centered: true,
      windowClass: 'version-history-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  notifyCreator() {
    this.toastr.success('An notification email has been sent to the creator.');
  }
}
