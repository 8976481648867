import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule, FormModule, CardModule, ButtonModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';

@Component({
  selector: 'app-forgot-password-notice',
  standalone: true,
  imports: [CommonModule, GridModule, FormModule, CardModule, ButtonModule, IconModule],
  templateUrl: './forgot-password-notice.component.html',
  styleUrl: './forgot-password-notice.component.scss'
})
export class ForgotPasswordNoticeComponent implements OnInit {
  email!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.email = atob(params['e'] ?? '');
    });
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
}
