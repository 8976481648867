<div class="bg-body flex-1 d-flex flex-row align-items-center py-5">
  <c-container class="mw-lg">
    <div class="d-flex justify-content-center">
      <c-card class="p-4">
        <c-card-body>
          <form cForm>
            <div class="mb-4">
              <h1>Forgot Password</h1>
            </div>

            <div class="mb-4">
              <p>We've sent you an email <strong>[{{email}}]</strong> with instructions to reset your password. </p>
              <p> Just follow the steps in the email, and you'll be back in your account in no time! </p>
              <p>If you can't find the email in your inbox, be sure to check your spam folder. </p>
            </div>

            <c-row>
              <c-col xs="6" class="mb-2">
                <button cButton class="px-4" color="primary" (click)="goToLogin()">
                  Back to Log In
                </button>
              </c-col>
            </c-row>
          </form>
        </c-card-body>
      </c-card>
    </div>
  </c-container>
</div>