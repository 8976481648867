<div class="bg-body flex-1 d-flex flex-row align-items-center py-5">
  <c-container class="mw-lg">
    <div class="d-flex justify-content-center">
      <c-card class="p-4">
        <c-card-body *ngIf="landingError">
          <form cForm>
            <div class="mb-3">
              <h1>Invalid Account Activation Link</h1>
            </div>

            <div class="mb-4">
              {{landingErrorMessage}}
            </div>
          </form>

          <button *ngIf="landingErrorCode === '2101'" cButton class="px-4" color="primary" (click)="goToLogin()">
            Back to Log In
          </button>
        </c-card-body>
        <c-card-body *ngIf="!landingError">
          <form cForm>
            <div class="mb-5">
              <h6 class="text-dark">Starbucks Coffee Community Admin Portal</h6>
              <h1>Create Password</h1>
            </div>

            <div class="mb-4">
              <div class="form-group mb-3">
                <app-text-input [config]="inputEmailConfig" (onChange)="setFormValue($event)"
                  autoComplete="username"></app-text-input>
              </div>

              <div class="form-group mb-3">
                <app-text-input [config]="inputPasswordConfig" (onChange)="setFormValue($event)"
                  autoComplete="new-password"></app-text-input>
              </div>

              <div class="form-group mb-3">
                <app-text-input [config]="inputConfirmPasswordConfig"
                  (onChange)="setFormValue($event)"></app-text-input>
              </div>

              <div class="form-group mb-3">
                <label cLabel for="pin-input">
                  OTP<span class="text-danger">*</span>
                </label>
                <c-input-group class="mb-2">
                  <input id="pin-input" autoComplete="one-time-code" cFormControl placeholder="Enter here" type="text"
                    [(ngModel)]="otpValue" (ngModelChange)="error = false; errorMessage = ''" name="pin-input"
                    autocomplete="one-time-code" />
                  <button cButton color="primary"
                    [disabled]="isRequestingOTP || requestOTPCountdownStart || firstTimeLoginForm.controls['email-input'].value === '' || firstTimeLoginForm.controls['password-input'].value === '' ||  firstTimeLoginForm.controls['confirm-password-input'].value === ''"
                    (click)="error = false; errorMessage = ''; onRequestOTP()">
                    <span *ngIf="!isRequestingOTP">{{ requestOTPButtonText }}</span>
                    <span *ngIf="isRequestingOTP" class="icon-img">
                      <icon-spinner></icon-spinner>
                    </span>
                  </button>
                </c-input-group>
                <div *ngIf="requestOTPCountdownStart">
                  <small><i>A one-time pin has been sent to your email</i></small>
                </div>
              </div>
              <div class="invalid-message py-1" *ngIf="error">{{errorMessage}}</div>
            </div>

            <c-row>
              <c-col xs="6" class="mb-2">
                <button cButton class="px-4" color="primary" (click)="onActivateAccount()"
                  [disabled]="firstTimeLoginForm.controls['email-input'].value === '' || firstTimeLoginForm.controls['password-input'].value === '' ||  firstTimeLoginForm.controls['confirm-password-input'].value === '' || otpValue === '' || isSubmitting">
                  <span *ngIf="!isSubmitting">Continue</span>
                  <span *ngIf="isSubmitting" class="icon-img">
                    <icon-spinner></icon-spinner>
                  </span>
                </button>
              </c-col>
            </c-row>
          </form>
        </c-card-body>
      </c-card>
    </div>
  </c-container>
</div>