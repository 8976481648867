import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cilCheckAlt,
  cibFacebook,
  cibGoogle,
  cibLinkedin,
  cibSkype,
  cibTwitter,
  cidImages,
  cidClipboard,
  cidContacts,
  cidBellExclamation,
  cidExclamationCircle,
  cidFolderAlt,
  cidFolderSpecial,
  cidLibraryBooks,
  cidPeople,
  cidSpreadsheet,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilAccountLogout,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilAvTimer,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCalendarPlus,
  cilChart,
  cilChartPie,
  cilCheck,
  cilCheckDoubleAlt,
  cilCheckCircle,
  cilChevronLeft,
  cilChevronLeftAlt,
  cilChevronRight,
  cilChevronRightAlt,
  cilClipboard,
  cilCloudDownload,
  cilCloudUpload,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCrop,
  cilCursor,
  cilDescription,
  cilDollar,
  cilDocument,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilExclamationCircle,
  cilFile,
  cilFileAdd,
  cilFindInPage,
  cilFileArchive,
  cilFilePdf,
  cilFileDoc,
  cilFileXls,
  cilFolder,
  cilFolderOpen,
  cilHamburgerMenu,
  cilHome,
  cilImage,
  cilImagePlus,
  cilInbox,
  cilInboxIn,
  cilIndentDecrease,
  cilIndentIncrease,
  cilItalic,
  cilJustifyCenter,
  cilLayers,
  cilLibrary,
  cilLibraryAdd,
  cilLink,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMediaPlay,
  cilMemory,
  cilMenu,
  cilMinusCircle,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPageview,
  cilPaperclip,
  cilPaperPlane,
  cilPen,
  cilPencil,
  cilPeople,
  cilPlaylistAddCheck,
  cilPlus,
  cilPrint,
  cilPuzzle,
  cilReportSlash,
  cilSave,
  cilSettings,
  cilSend,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShortText,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilSync,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserPlus,
  cilUserUnfollow,
  cilViewCarousel,
  cilWarning,
  cilX,
  cisOptions
} from '@coreui/icons-pro';

export const iconSubset = {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cilCheckAlt,
  cibFacebook,
  cibGoogle,
  cibLinkedin,
  cibSkype,
  cibTwitter,
  cidImages,
  cidBellExclamation,
  cidExclamationCircle,
  cidClipboard,
  cidContacts,
  cidFolderAlt,
  cidFolderSpecial,
  cidLibraryBooks,
  cidPeople,
  cidSpreadsheet,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilAccountLogout,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilAvTimer,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCalendarPlus,
  cilChart,
  cilChartPie,
  cilCheck,
  cilCheckDoubleAlt,
  cilCheckCircle,
  cilChevronLeft,
  cilChevronLeftAlt,
  cilChevronRight,
  cilChevronRightAlt,
  cilClipboard,
  cilCloudDownload,
  cilCloudUpload,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCrop,
  cilCursor,
  cilDescription,
  cilDollar,
  cilDocument,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilExclamationCircle,
  cilFile,
  cilFileAdd,
  cilFileArchive,
  cilFilePdf,
  cilFileDoc,
  cilFileXls,
  cilFindInPage,
  cilFolder,
  cilFolderOpen,
  cilHamburgerMenu,
  cilHome,
  cilImage,
  cilImagePlus,
  cilInbox,
  cilInboxIn,
  cilIndentDecrease,
  cilIndentIncrease,
  cilItalic,
  cilJustifyCenter,
  cilLayers,
  cilLibrary,
  cilLibraryAdd,
  cilLink,
  cilList,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMediaPlay,
  cilMemory,
  cilMenu,
  cilMinusCircle,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPageview,
  cilPaperclip,
  cilPaperPlane,
  cilPen,
  cilPencil,
  cilPeople,
  cilPlaylistAddCheck,
  cilPlus,
  cilPrint,
  cilPuzzle,
  cilReportSlash,
  cilSave,
  cilSend,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShortText,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilSync,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserPlus,
  cilUserUnfollow,
  cilViewCarousel,
  cilWarning,
  cilX,
  cisOptions
};

export enum IconSubset {
  cibCcAmex = 'cibCcAmex',
  cibCcApplePay = 'cibCcApplePay',
  cibCcMastercard = 'cibCcMastercard',
  cibCcPaypal = 'cibCcPaypal',
  cibCcStripe = 'cibCcStripe',
  cibCcVisa = 'cibCcVisa',
  cilCheckAlt = 'cilCheckAlt',
  cibFacebook = 'cibFacebook',
  cibGoogle = 'cibGoogle',
  cibLinkedin = 'cibLinkedin',
  cibSkype = 'cibSkype',
  cibTwitter = 'cibTwitter',
  cidClipboard = 'cidClipboard',
  cidContacts = 'cidContacts',
  cidImages = 'cidImages',
  cidBellExclamation = 'cidBellExclamation',
  cidExclamationCircle = 'cidExclamationCircle',
  cidFolderAlt = 'cidFolderAlt',
  cidFolderSpecial = 'cidFolderSpecial',
  cidLibraryBooks = 'cidLibraryBooks',
  cidPeople = 'cidPeople',
  cidSpreadsheet = 'cidSpreadsheet',
  cifBr = 'cifBr',
  cifEs = 'cifEs',
  cifFr = 'cifFr',
  cifIn = 'cifIn',
  cifPl = 'cifPl',
  cifUs = 'cifUs',
  cilAlignCenter = 'cilAlignCenter',
  cilAlignLeft = 'cilAlignLeft',
  cilAlignRight = 'cilAlignRight',
  cilApplicationsSettings = 'cilApplicationsSettings',
  cilArrowBottom = 'cilArrowBottom',
  cilArrowRight = 'cilArrowRight',
  cilArrowTop = 'cilArrowTop',
  cilAvTimer = 'cilAvTimer',
  cilBasket = 'cilBasket',
  cilBell = 'cilBell',
  cilBold = 'cilBold',
  cilBookmark = 'cilBookmark',
  cilCalculator = 'cilCalculator',
  cilCalendar = 'cilCalendar',
  cilCalendarPlus = 'cilCalendarPlus',
  cilChart = 'cilChart',
  cilChartPie = 'cilChartPie',
  cilCheck = 'cilCheck',
  cilCheckDoubleAlt = 'cilCheckDoubleAlt',
  cilCheckCircle = 'cilCheckCircle',
  cilChevronLeft = 'cilChevronLeft',
  cilChevronLeftAlt = 'cilChevronLeftAlt',
  cilChevronRight = 'cilChevronRight',
  cilChevronRightAlt = 'cilChevronRightAlt',
  cilClipboard = 'cilClipboard',
  cilCloudDownload = 'cilCloudDownload',
  cilCloudUpload = 'cilCloudUpload',
  cilCode = 'cilCode',
  cilCommentSquare = 'cilCommentSquare',
  cilCreditCard = 'cilCreditCard',
  cilCrop = 'cilCrop',
  cilCursor = 'cilCursor',
  cilDescription = 'cilDescription',
  cilDollar = 'cilDollar',
  cilDocument = 'cilDocument',
  cilDrop = 'cilDrop',
  cilEnvelopeClosed = 'cilEnvelopeClosed',
  cilEnvelopeOpen = 'cilEnvelopeOpen',
  cilExclamationCircle = 'cilExclamationCircle',
  cilFile = 'cilFile',
  cilFileAdd = 'cilFileAdd',
  cilFileArchive = 'cilFileArchive',
  cilFilePdf = 'cilFilePdf',
  cilFileDoc = 'cilFileDoc',
  cilFileXls = 'cilFileXls',
  cilFindInPage = 'cilFindInPage',
  cilFolder = 'cilFolder',
  cilFolderOpen = 'cilFolderOpen',
  cilHamburgerMenu = 'cilHamburgerMenu',
  cilHome = 'cilHome',
  cilImage = 'cilImage',
  cilImagePlus = 'cilImagePlus',
  cilInbox = 'cilInbox',
  cilInboxIn = 'cilInboxIn',
  cilIndentDecrease = 'cilIndentDecrease',
  cilIndentIncrease = 'cilIndentIncrease',
  cilItalic = 'cilItalic',
  cilJustifyCenter = 'cilJustifyCenter',
  cilLayers = 'cilLayers',
  cilLibrary = 'cilLibrary',
  cilLibraryAdd = 'cilLibraryAdd',
  cilLink = 'cilLink',
  cilList = 'cilList',
  cilListNumbered = 'cilListNumbered',
  cilLocationPin = 'cilLocationPin',
  cilLockLocked = 'cilLockLocked',
  cilMagnifyingGlass = 'cilMagnifyingGlass',
  cilMap = 'cilMap',
  cilMediaPlay = 'cilMediaPlay',
  cilMemory = 'cilMemory',
  cilMenu = 'cilMenu',
  cilMinusCircle = 'cilMinusCircle',
  cilMoon = 'cilMoon',
  cilNotes = 'cilNotes',
  cilOptions = 'cilOptions',
  cilPageview = 'cilPageview',
  cilPaperclip = 'cilPaperclip',
  cilPaperPlane = 'cilPaperPlane',
  cilPen = 'cilPen',
  cilPencil = 'cilPencil',
  cilPeople = 'cilPeople',
  cilPlaylistAddCheck = 'cilPlaylistAddCheck',
  cilPlus = 'cilPlus',
  cilPrint = 'cilPrint',
  cilPuzzle = 'cilPuzzle',
  cilReportSlash = 'cilReportSlash',
  cilSave = 'cilSave',
  cilSend = 'cilSend',
  cilSettings = 'cilSettings',
  cilShare = 'cilShare',
  cilShareAll = 'cilShareAll',
  cilShareBoxed = 'cilShareBoxed',
  cilShortText = 'cilShortText',
  cilSpeech = 'cilSpeech',
  cilSpeedometer = 'cilSpeedometer',
  cilSpreadsheet = 'cilSpreadsheet',
  cilStar = 'cilStar',
  cilSun = 'cilSun',
  cilSync = 'cilSync',
  cilTags = 'cilTags',
  cilTask = 'cilTask',
  cilTrash = 'cilTrash',
  cilUnderline = 'cilUnderline',
  cilUser = 'cilUser',
  cilUserFemale = 'cilUserFemale',
  cilUserFollow = 'cilUserFollow',
  cilUserPlus = 'cilUserPlus',
  cilUserUnfollow = 'cilUserUnfollow',
  cilViewCarousel = 'cilViewCarousel',
  cilWarning = 'cilWarning',
  cilX = 'cilX',
  cisOptions = 'cisOptions'
}
