import { Component, OnInit } from '@angular/core';
import { GridModule, ButtonModule, CardComponent } from '@coreui/angular';
import { RouterLink } from '@angular/router';
import { IconModule } from '@coreui/icons-angular';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CheckerStatusSummaryComponent } from "../../components/checker-status-summary/checker-status-summary.component";
import { CheckerContentSidePanelComponent } from "../../components/checker-content-side-panel/checker-content-side-panel.component";
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface selectedContent {
  id: string;
  name: string;
  active_period: string;
  img_url?: string;
}

@Component({
  selector: 'app-check-banner-order-settings',
  standalone: true,
  imports: [GridModule, ButtonModule, CardComponent, CommonModule, IconModule, CheckerStatusSummaryComponent, CheckerContentSidePanelComponent, RouterLink],
  templateUrl: './check-banner-order-settings.component.html',
  styleUrl: './check-banner-order-settings.component.scss'
})
export class CheckBannerOrderSettingsComponent {
  sidePanelConfig = {
    content_status: 'PENDING_APPROVAL',
    content_id: {
      value: '',
      disabled: false,
      error_message: ''
    },
    content_direct_link: '',
    content_preview_link: 'https://stg.starbuckscoffeeexpo.com/about-us',
    publish_period: {
      start_date: "",
      start_date_disabled: false,
      end_date: "",
      end_date_disabled: false,
      error_message: ""
    },
    bap_level: {
      disabled: false
    },
    language: {
      current_language: "English"
    }
  };

  statusSummaryConfig = {
    content_status: 'PENDING_APPROVAL'
  }


  selectedContent = [
    {
      id: 'partner-home-banner-1',
      name: 'Banner title goes here',
      active_period: '1 May 2024 - 31 Dec 2024 (GMT+8)',
      img_url: '/assets/images/placeholder/img-placeholder-image-16by9.webp'
    },
    {
      id: 'partner-home-banner-2',
      name: 'Banner title goes here',
      active_period: '1 May 2024 - 31 Dec 2024 (GMT+8)',
      img_url: '/assets/images/placeholder/img-placeholder-image-16by9.webp'
    },
    {
      id: 'partner-home-banner-3',
      name: 'Banner title goes here',
      active_period: '1 May 2024 - 31 Dec 2024 (GMT+8)',
      img_url: '/assets/images/placeholder/img-placeholder-image-16by9.webp'
    }
  ];

  constructor(private location: Location) { }

  goBack(): void {
    this.location.back();
  }
}
