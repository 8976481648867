<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>

    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0 btn-user-avatar">
      <c-avatar cTextColor="primary" shape="rounded-1" size="md" src="./assets/images/avatars/img-avatar-default.webp">
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 user-dropdown">
      <li class="account-info">
        <!-- <h6 cDropdownHeader class="bg-light fw-semibold pt-2 pb-1">Account</h6> -->
        <div class="info bg-light px-3 py-3 mb-2">
          <div class="user-name mb-1">{{adminName}}</div>
          <div class="user-email mb-2">{{adminEmail}}</div>
          <div class="user-role"><span class="label">{{adminRole}}</span></div>
        </div>

      </li>
      <li>
        <a (click)="openLogoutConfirmationModal()" cDropdownItem>
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          Log out
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>