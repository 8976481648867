import { Component, OnInit } from '@angular/core';
import { GridModule, ButtonModule, CardComponent } from '@coreui/angular';
import { RouterLink } from '@angular/router';
import { ListFiltersComponent } from "../../components/list-filters/list-filters.component";
import { IconModule } from '@coreui/icons-angular';
import { ListTableComponent } from "../../components/list-table/list-table.component";
import { listFilterConfigModel } from 'src/app/_models/content.model';
import { AuditService } from 'src/app/_services/audit.service';
import { TableBodyDataModel } from 'src/app/_models/select-content-modal.model';
import { ErrorService } from 'src/app/_services/error.service';
import { ContentService } from 'src/app/_services/content.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ListPaginationComponent } from 'src/app/components/list-pagination/list-pagination.component';

@Component({
  selector: 'app-audit-trails',
  standalone: true,
  imports: [GridModule, ButtonModule, CardComponent, RouterLink, ListFiltersComponent, IconModule, ListTableComponent, CommonModule, FormsModule, ListPaginationComponent],
  templateUrl: './audit-trails.component.html',
  styleUrl: './audit-trails.component.scss'
})
export class AuditTrailsComponent implements OnInit {
  listFilterConfig: listFilterConfigModel = {
    mode: 'AUDIT_TRAILS',
    has_active_period: true
  }

  // API Call Config
  currentPage: number = 1;
  showAsset: number = 15;
  totalAsset: number = 0;
  totalPage: number = 1;
  filter: {
    role: string,
    status: string,
    event: string,
    dateRange: Date[]
  } = {
      role: 'ALL',
      status: 'ALL',
      event: 'ALL',
      dateRange: []
    }

  searchInput: string = '';
  searchTerms = 'admin name, email';

  tableHeadData = [
    {
      'value': 'Date',
      'class': 'col-wide-lg sticky'
    },
    {
      'value': 'Name',
      'class': 'col-wide-md name'
    },
    {
      'value': 'Email',
      'class': 'col-wide-md'
    },
    {
      'value': 'Role',
      'class': 'col-wide-sm'
    },
    {
      'value': 'Activity',
      'class': 'col-wide-lg'
    },
    {
      'value': 'Status',
      'class': 'col-wide-sm status'
    }
  ];

  dynamicTableBodyData: TableBodyDataModel[][] = [];

  constructor(
    private auditService: AuditService,
    private errorService: ErrorService,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.getOrSearch();
  }

  setFilterValue(data: { formControlName: string, value: any }) {
    switch (data.formControlName.toUpperCase()) {
      case 'AUDIT-ROLE-INPUT':
        this.filter.role = data.value;
        break;
      case 'AUDIT-STATUS-INPUT':
        this.filter.status = data.value;
        break;
      case 'AUDIT-EVENT-INPUT':
        this.filter.event = data.value;
        break;
      case 'AUDIT-DATE-RANGE-INPUT':
        this.filter.dateRange = data.value;
        break;
      default:
        break;
    }
    this.currentPage = 1;
    this.getOrSearch();
  }

  setSearchInput(data: string) {
    this.searchInput = data;
    this.currentPage = 1;
    this.getOrSearch();
  }

  getOrSearch() {
    if (!this.filter.role) {
      this.errorService.openGenericErrorModal('Please select a role!');
      return;
    }
    if (!this.filter.event) {
      this.errorService.openGenericErrorModal('Please select an event!');
      return;
    }
    if (!this.filter.status) {
      this.errorService.openGenericErrorModal('Please select a status!');
      return;
    }
    this.contentService.openFullscreenSpinnerModal();
    this.auditService.getHistory(this.filter.event, this.filter.role, this.currentPage, this.showAsset, this.filter.status, this.searchInput, this.filter.dateRange).subscribe({
      next: res => {
        this.contentService.spinnerModalRef.dismiss();
        if (!res.data) {
          this.errorService.openGenericErrorModal(res.error, res.error_code);
          return;
        }

        this.dynamicTableBodyData = [];
        if (res.data.content.length === 0) {
          this.totalAsset = 0;
          this.totalPage = 1;
          this.currentPage = 1;
          return;
        }

        const contentData = res.data.content;
        contentData.forEach((event: any) => {
          this.dynamicTableBodyData.push([
            {
              'type': 'TEXT',
              'value': this.contentService.formatDateTime(event.created_at),
              'class': 'sticky'
            },
            {
              'type': 'TEXT',
              'value': event.name,
              'class': 'name'
            },
            {
              'type': 'TEXT',
              'value': event.email
            },
            {
              'type': 'TEXT',
              'value': event.role
            },
            {
              'type': 'TEXT',
              'value': event.event
            },
            {
              'type': 'TEXT',
              'value': event.status,
              'isAlert': event.status.toUpperCase() === 'FAIL',
              'class': 'status'
            }
          ]);
        });
        this.totalAsset = res.data.total_elements;
        this.totalPage = res.data.total_pages;
      }
    })
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
    this.getOrSearch();
  }
}
