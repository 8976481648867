<div class="d-flex flex-column min-vh-100">
  <header>
    <c-container>
      <div class="d-flex align-items-center justify-content-center py-3">
        <figure class="brand-logo mb-0">
          <a href="/" class="header-logo-link">
            <img src='assets/images/brand/img-logo-sbx-white.svg' />
          </a>
        </figure>

      </div>
    </c-container>
  </header>
  <div class="body-wrapper flex-1 d-flex flex-column">
    <router-outlet />
  </div>
  <footer class="bg-body-dark">
    <c-container>
      <div class="gray-600 fs-small py-3 text-center">
        &copy; {{currentYear}} Starbucks Corporation All Rights Reserved v{{currentVersion}}
      </div>
    </c-container>
  </footer>
</div>