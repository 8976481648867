import { Routes } from '@angular/router';
import { DefaultLayoutComponent, PublicLayoutComponent } from './containers';
import { Page404Component } from './views/page404/page404.component';
import { Page500Component } from './views/page500/page500.component';
import { LoginComponent } from './views/login/login.component';
import { FirstTimeLoginComponent } from './views/first-time-login/first-time-login.component';
import { AccountActivatedComponent } from './views/account-activated/account-activated.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ForgotPasswordNoticeComponent } from './views/forgot-password-notice/forgot-password-notice.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ResetPasswordSuccessfulComponent } from './views/reset-password-successful/reset-password-successful.component';
import { CreateContentSuccessfulComponent } from './views/create-content-successful/create-content-successful.component';
import { CheckBannerOrderSettingsComponent } from './views/check-banner-order-settings/check-banner-order-settings.component';
import { PendingApprovalListComponent } from './views/pending-approval-list/pending-approval-list.component';
import { EmailTemplatesComponent } from './views/email-templates/email-templates.component';
import { userAuthGuard, auditTrailAuthGuard, pendingApprovalAuthGuard } from './_helpers/user-auth.guard';
import { clientAuthGuard } from './_helpers/client-auth.guard';
import { AuditTrailsComponent } from './views/audit-trails/audit-trails.component';

const homePath = '/reports';

export const routes: Routes = [
  {
    path: '',
    redirectTo: homePath,
    pathMatch: 'full'
  },
  {
    path: '',
    component: PublicLayoutComponent,
    data: {
      title: ''
    },
    children: [
      {
        path: '404',
        component: Page404Component,
        data: {
          title: 'Page 404'
        }
      },
      {
        path: '500',
        component: Page500Component,
        data: {
          title: 'Page 500'
        }
      },
      {
        path: 'first-time-login',
        component: FirstTimeLoginComponent,
        data: {
          title: 'First Time Log In'
        }
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: {
          title: 'Reset Password'
        }
      },
      {
        path: 'reset-password-successful',
        component: ResetPasswordSuccessfulComponent,
        data: {
          title: 'Reset Password Successful'
        }
      }
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [userAuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'public-home',
        loadChildren: () =>
          import('./views/public-home/public-home-routing.module').then((m) => m.routes)
      },
      {
        path: 'partner-home',
        loadChildren: () =>
          import('./views/partner-home/partner-home-routing.module').then((m) => m.routes)
      },
      {
        path: 'event',
        loadChildren: () =>
          import('./views/event/event-routing.module').then((m) => m.routes)
      },
      {
        path: 'learning-centre',
        loadChildren: () =>
          import('./views/learning-centre/learning-centre-routing.module').then((m) => m.routes)
      },
      {
        path: 'competition',
        loadChildren: () =>
          import('./views/competition/competition-routing.module').then((m) => m.routes)
      },
      {
        path: 'bap',
        loadChildren: () =>
          import('./views/bap/bap-routing.module').then((m) => m.routes)
      },
      {
        path: 'workshop',
        loadChildren: () =>
          import('./views/workshop/workshop-routing.module').then((m) => m.routes)
      },
      {
        path: 'person',
        loadChildren: () =>
          import('./views/person/person-routing.module').then((m) => m.routes)
      },
      {
        path: 'all-contents',
        loadChildren: () =>
          import('./views/all-contents/all-contents-routing.module').then((m) => m.routes)
      },
      {
        path: 'asset-library',
        loadChildren: () =>
          import('./views/asset-library/asset-library-routing.module').then((m) => m.routes)
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./views/reports/reports-routing.module').then((m) => m.routes)
      },
      {
        path: 'manage-admins',
        loadChildren: () =>
          import('./views/manage-admins/manage-admins-routing.module').then((m) => m.routes)
      },
      {
        path: 'manage-partners',
        loadChildren: () =>
          import('./views/manage-partners/manage-partners-routing.module').then((m) => m.routes)
      },
      {
        path: 'content-creation-successful',
        component: CreateContentSuccessfulComponent
      },
      {
        path: 'check-banner-order-settings',
        data: {
          title: 'Check Banner Order Settings'
        },
        component: CheckBannerOrderSettingsComponent
      },
      {
        path: 'pending-approval',
        canActivate: [pendingApprovalAuthGuard],
        data: {
          title: 'Pending Approval'
        },
        component: PendingApprovalListComponent
      },
      {
        path: 'audit-trails',
        canActivate: [auditTrailAuthGuard],
        data: {
          title: 'Audit Trails',
        },
        component: AuditTrailsComponent
      },
      {
        path: 'email-templates',
        data: {
          title: 'Email Templates'
        },
        component: EmailTemplatesComponent
      }
    ]
  },
  {
    path: '',
    component: PublicLayoutComponent,
    canActivate: [clientAuthGuard],
    data: {
      title: ''
    },
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: {
          title: 'Login Page'
        }
      },
      {
        path: 'account-activated',
        component: AccountActivatedComponent,
        data: {
          title: 'Account Activated'
        }
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: {
          title: 'Forgot Password'
        }
      },
      {
        path: 'forgot-password-notice',
        component: ForgotPasswordNoticeComponent,
        data: {
          title: 'Forgot Password Notice'
        }
      },

    ]
  },

  { path: '**', redirectTo: homePath }
];


// @NgModule({
//   imports: [
//     RouterModule.forRoot(routes, {
//       scrollPositionRestoration: 'top',
//       anchorScrolling: 'enabled',
//       initialNavigation: 'enabledBlocking'
//       // relativeLinkResolution: 'legacy'
//     })
//   ],
//   exports: [RouterModule]
// })
// export class AppRoutingModule {
// }
