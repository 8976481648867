import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { GridModule, FormModule, CardModule, ButtonModule } from '@coreui/angular';

@Component({
  selector: 'app-page500',
  standalone: true,
  templateUrl: './page500.component.html',
  styleUrls: ['./page500.component.scss'],
  imports: [CommonModule, GridModule, FormModule, CardModule, ButtonModule]
})
export class Page500Component {

  constructor(private router: Router) { }

  goToHomepage() {
    this.router.navigateByUrl('/home');
  }

}
