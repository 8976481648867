import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule, FormModule, CardModule, ButtonModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { TextInputComponent } from "../../components/forms/";
import { InputType } from 'src/app/enum';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorService } from 'src/app/_services/error.service';
import { ValidationService } from 'src/app/_services/validation.service';
import { AuthService } from 'src/app/_services/auth.service';
import { debounceTime } from 'rxjs';
import { InputTextModel } from 'src/app/_models/form-field.model';
import { HttpErrorResponse } from '@angular/common/http';
import { SpinnerIcon } from 'src/app/icons/icon-spinner';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [CommonModule, GridModule, FormModule, FormsModule, CardModule, ButtonModule, IconModule, TextInputComponent, ReactiveFormsModule, SpinnerIcon]
})
export class LoginComponent implements OnInit, OnDestroy {
  requestOTPCountdown: number = 60;
  requestOTPCountdownStart: boolean = false;
  requestOTPButtonText: string = 'Request OTP';
  requestOTPInterval!: any;
  isRequestingOTP: boolean = false;

  // Form
  loginForm!: FormGroup;
  isSubmitting: boolean = false;

  // Error
  error: boolean = false;
  errorMessage!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private validationService: ValidationService,
    private errorService: ErrorService,
    private authService: AuthService
  ) { }

  // Form input - Email
  inputEmailConfig: InputTextModel = {
    id: 'email-input',
    name: 'email-input',
    label: 'Email',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    type: InputType.Email,
    required: true
  };

  // Form input - Password
  inputPasswordConfig: InputTextModel = {
    id: 'password-input',
    name: 'password-input',
    label: 'Password',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    type: InputType.Password,
    required: true
  };

  ngOnDestroy(): void {
    clearInterval(this.requestOTPInterval);
  }

  ngOnInit(): void {
    // Form Group
    this.loginForm = new FormGroup({
      'email-input': new FormControl('', [Validators.required]),
      'password-input': new FormControl('', [Validators.required]),
      pin: new FormControl('', [Validators.required]),
    });

    this.route.queryParams.subscribe(params => {
      if (params['e']) {
        this.inputEmailConfig.value = atob(params['e']);
        this.loginForm.controls['email-input'].setValue(atob(params['e']));
      }
    })

    // sanitise email input
    this.loginForm.controls['email-input'].valueChanges
      .pipe(
        debounceTime(500),
      )
      .subscribe(
        (val: string) => {
          const sanitizedVal = val.replace(/\s/g, "").toLowerCase();
          this.loginForm.controls['email-input'].patchValue(sanitizedVal, { emitEvent: false });
          this.inputEmailConfig.value = sanitizedVal;
        }
      )

    // Set password inputConfig value
    this.loginForm.controls['password-input'].valueChanges.subscribe(newValue => {
      this.inputPasswordConfig.value = newValue;
    });
  }

  onRequestOTP(): void {
    this.isRequestingOTP = true;
    if (this.validationService.validateEmail(this.loginForm.controls['email-input'].value)) {
      this.authService.requestOtpForLogin(this.loginForm.controls['email-input'].value, this.loginForm.controls['password-input'].value).subscribe({
        next: res => {
          if (res.data) {
            this.requestOTPCountdown = 60;
            this.requestOTPCountdownStart = true;
            this.requestOTPInterval = setInterval(() => {
              this.requestOTPCountdown--;

              if (this.requestOTPCountdown === 0) {
                clearInterval(this.requestOTPInterval);
                this.requestOTPCountdownStart = false;
                this.requestOTPButtonText = 'Request OTP';
              } else {
                this.requestOTPButtonText = `Request OTP (${this.requestOTPCountdown})`;
              }
            }, 1000);
            this.isRequestingOTP = false;
            return;
          }
          clearInterval(this.requestOTPInterval);
          this.requestOTPButtonText = 'Request OTP';
          this.requestOTPCountdownStart = false;
          this.errorMessage = res.error;
          this.error = true;
          this.isRequestingOTP = false;
        }
      })
    } else {
      this.errorMessage = this.errorService.getFrontendErrorMapping('5002');
      this.error = true;
      this.isRequestingOTP = false;
    }
  }

  onSubmitLogin() {
    this.isSubmitting = true;
    if (this.validationService.validateEmail(this.loginForm.controls['email-input'].value)) {
      this.authService.userAuth(this.loginForm.controls['email-input'].value, this.loginForm.controls['password-input'].value, this.loginForm.controls['pin'].value).subscribe({
        next: res => {
          localStorage.setItem('auth', JSON.stringify(res));
          this.isSubmitting = false;
        },
        error: (err: HttpErrorResponse) => {
          this.isSubmitting = false;
          this.errorMessage = err.error.error_description.substr(7);
          this.error = true;
        },
        complete: () => {
          this.router.navigate(['/reports']);
        },
      })
    } else {
      this.isSubmitting = false;
      this.errorMessage = this.errorService.getFrontendErrorMapping('5002');
      this.error = true;
    }
  }

  goToForgotPassword() {
    this.router.navigateByUrl('/forgot-password');
  }

  setTextInputValue(newValue: { formControlName: string, value: string }) {
    this.loginForm.controls[newValue.formControlName].setValue(newValue.value);
    this.error = false;
  }
}

