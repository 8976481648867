import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { environment } from 'src/environment/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService
    ) { }

    clientAuthEndpoints = [
        `${environment.prefixPath}${environment.adminPath}${environment.authPath}`
    ];

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this.authService.getClientAccessToken(); //get client token instead (to accomondate prelogin use)

        if (environment.clientAuthEnabled) {
            if (token && !request.url.includes("/oauth/token")) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }
        } else {
            // exclude endpoints that requires client auth
            if (this.clientAuthEndpoints.filter(e => request.url.includes(e)).length <= 0) {
                if (token && !request.url.includes("/oauth/token")) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                }
            }
        }

        if (request.url.includes("logout")) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError(err => {
                console.log("api error!");

                if (err.status === 400) {
                    return throwError(() => err);
                }

                if (err.status === 401 && request.url.includes("/oauth/token")) {
                    console.log('Interceptor: wrong login!');
                    return throwError(() => err);
                }

                if (err.status === 401) {
                    this.authService.userLogout();
                }

                if (err.status === 403) {
                    this.authService.userLogout();
                }

                if (err.error != null) {
                    const error = err.error.message || err.error || err.statusText;
                    return throwError(() => error);
                }
                return throwError(() => err);
            })
        );
    }
}