<section class="page-header">
  <c-container>
    <c-row>
      <c-col>
        <div class="page-heading text-center text-lg-start">
          <h1>Pending Approval</h1>
        </div>

      </c-col>
    </c-row>
  </c-container>
</section>

<section class="page-contents listing">
  <c-container>
    <c-row>
      <c-col lg="3" class="left-panel">
        <app-list-filters [config]="listFilterConfig" (onFilter)="setFilterValue($event)"></app-list-filters>
      </c-col>
      <c-col lg="9" class="right-panel">
        <div class="card light-shadow border-0">
          <div class="card-body">
            <app-list-table [tableSearch]="true" [searchTerms]="searchTerms" [tableHeadData]="tableHeadData"
              [tableBodyData]="dynamicTableBodyData" [totalAsset]="totalAsset"
              (onSearch)="setSearchInput($event)"></app-list-table>
          </div>
        </div>

        <div *ngIf="dynamicTableBodyData.length > 0" class="pagination mt-5">
          <c-row class="w-100">
            <c-col xl="8">
              <app-list-pagination [currentPage]="currentPage" [totalPage]="totalPage"
                (onSetPage)="setCurrentPage($event)"></app-list-pagination>
            </c-col>

            <c-col xl="4">
              <div class="form-group d-flex flex-wrap align-items-center justify-content-center justify-content-xl-end">
                <label cLabel for="list-filter-language" class="me-2 mb-0">Show</label>
                <select cSelect id="list-filter-language" [(ngModel)]="showAsset"
                  (ngModelChange)="currentPage = 1; getOrSearch()" class="w-auto" aria-label="Language">
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="100">100</option>
                </select>
              </div>
            </c-col>
          </c-row>
        </div>

      </c-col>
    </c-row>
  </c-container>
</section>