import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { GridModule, FormModule, CardModule, ButtonModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';

@Component({
  selector: 'app-reset-password-successful',
  standalone: true,
  imports: [CommonModule, GridModule, FormModule, CardModule, ButtonModule, IconModule],
  templateUrl: './reset-password-successful.component.html',
  styleUrl: './reset-password-successful.component.scss'
})
export class ResetPasswordSuccessfulComponent {
  constructor(private router: Router) { }

  goToLogin() {
    this.router.navigateByUrl('/login');
  }
}
